import { Features } from '@finn/ua-featureflags';
import { isServer, Locale } from '@finn/ui-utils';
import Cookies from 'js-cookie';
import { SWRConfiguration } from 'swr';
import useSWR from 'swr/immutable';

import { dynamicFetcher } from '../data/fetchers';
import { useDiscoveryConfig } from '../data/MetadataProvider';
import {
  FiltersResponse,
  GetFiltersParams,
  PRICING_TYPE,
  VehicleViewKey,
} from '../helpers/filters';

export const useFetchFilters = (
  params: GetFiltersParams,
  isOutOfStock?: boolean,
  swrConfig?: SWRConfiguration
) => {
  const config = useDiscoveryConfig();
  const { deals } = config;
  const hideDownpayment =
    !isServer() &&
    Cookies.get(`${params.locale}_${Features.HideDownPayment}`) === 'b';

  if (!params.filters) {
    params.filters = {};
  }

  if (deals && deals.length > 0) {
    params.filters.view = VehicleViewKey.AVAILABLE_AND_COMING_SOON;
  } else if (isOutOfStock) {
    params.filters.view = VehicleViewKey.DISPLAYED_CARS;
  }

  params.pricingType = hideDownpayment
    ? PRICING_TYPE.NORMAL
    : PRICING_TYPE.DOWNPAYMENT;
  params.hide_related = true;

  if (!params?.filters.view) {
    params.filters.view =
      params.locale === Locale.ENGLISH_USA
        ? VehicleViewKey.AVAILABLE_AND_COMING_SOON
        : VehicleViewKey.AVAILABLE;
  }
  const cugId = !isServer() ? Cookies.get('cug_id') : null;

  return useSWR<FiltersResponse>(
    params && {
      url: config.apiConfig.filters,
      query: {
        ...params.filters,
        hide_related: params.hide_related,
        zipcode: params.zipCode,
        pricing_type: params.pricingType,
        cug_id: cugId,
      },
    },
    dynamicFetcher,
    swrConfig
  );
};

import { IntlShape } from '@finn/ui-utils';
import { getTermLabel } from '@finn/ui-utils/ssr';

import {
  FilterKey,
  FiltersResponse,
  FilterValuesObject,
  FilterValuesObjectSchema,
} from './filters';

export function parseFilterValues(input: object): FilterValuesObject {
  const parsed: FilterValuesObject = {};

  for (const [filterKey, filterValue] of Object.entries(input)) {
    const result = FilterValuesObjectSchema.safeParse({
      [filterKey]: filterValue,
    });

    if (!result.success || !result.data) {
      continue;
    }

    const parsedValue = result.data[filterKey as keyof FilterValuesObject];
    if (
      !parsedValue ||
      (Array.isArray(parsedValue) && parsedValue.length === 0) ||
      (typeof parsedValue === 'object' && Object.keys(parsedValue).length === 0)
    ) {
      continue;
    }

    (parsed as unknown as Record<string, unknown>)[filterKey] = parsedValue;
  }

  return parsed;
}

export type Option<T = string> = {
  label: string;
  value: T;
  disabled?: boolean;
};

const getTermsFilterData = (
  terms: Array<number>,
  intl: IntlShape,
  flexTermLabel: string
): Option[] =>
  terms?.map((term: number) => ({
    label: getTermLabel(term, flexTermLabel, intl),
    value: String(term),
  }));

export const getFeaturesFilterData = (
  features: Array<string>,
  intl: IntlShape
): Option[] => {
  return (
    features?.map((feature: string) => ({
      label: String(
        intl.formatMessage(`plp.features.filters.${feature}`, {
          defaultMessage: '',
        })
      ),
      value: feature,
    })) || []
  );
};

export const parseFiltersResponse = (
  filterKey: FilterKey,
  filtersResponse: FiltersResponse,
  i18n: IntlShape
) => {
  if (filterKey === FilterKey.TERMS) {
    return getTermsFilterData(filtersResponse[filterKey], i18n, 'Flex');
  }
  if (filterKey === FilterKey.FEATURES) {
    return getFeaturesFilterData(filtersResponse[filterKey], i18n);
  }

  return filtersResponse[filterKey];
};

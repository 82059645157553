import { VehicleOfferType } from '@finn/ui-utils/ssr';
import union from 'lodash/union';
import { z } from 'zod';

import { type PricingType } from '../../product-pricing';

export const PRICING_TYPE = {
  NORMAL: 'normal',
  DOWNPAYMENT: 'downpayment',
} as const;

export enum FilterKey {
  SORT = 'sort',
  BRANDS = 'brands',
  CAR_TYPES = 'cartypes',
  MODELS = 'models',
  GEARSHIFTS = 'gearshifts',
  FUELS = 'fuels',
  COLORS = 'colors',
  HAS_HITCH = 'has_hitch',
  VIEW = 'view',
  IS_FOR_BUSINESS = 'is_for_business',
  IS_YOUNG_DRIVER = 'is_young_driver',
  MIN_PRICE = 'min_price',
  MAX_PRICE = 'max_price',
  MIN_PRICE_MSRP = 'min_price_msrp',
  MAX_PRICE_MSRP = 'max_price_msrp',
  MIN_POWER = 'min_power',
  MAX_POWER = 'max_power',
  AVAILABLE_FROM = 'available_from',
  AVAILABLE_TO = 'available_to',
  TERMS = 'terms',
  OFFER_TYPE = 'offer_type',
  SORT_BY_POPULARITY = 'sort_by_popularity',
  RETENTION = 'retention',
  HAS_DEALS = 'has_deals',
  PRODUCT_GROUP = 'product_group',
  HAS_RETENTION_DEALS = 'has_retention_deals',
  FEATURES = 'features',
  TOTAL_RESULTS = 'total_results',
  POPULAR = 'popular_filters',
  COMPANY_ID = 'company_id',
  HIDE_RELATED = 'hide_related',
  // eslint-disable-next-line
  PRICING_TYPE = 'pricing_type',
}

export const AVAILABILITY_KEY = 'availability';

export type FiltersResponse = {
  [AVAILABILITY_KEY]: string[];
  [FilterKey.BRANDS]: Brand[];
  [FilterKey.COLORS]: Color[];
  [FilterKey.CAR_TYPES]: string[];
  [FilterKey.MODELS]: string[];
  [FilterKey.GEARSHIFTS]: string[];
  [FilterKey.FUELS]: string[];
  [FilterKey.TERMS]: number[];
  [FilterKey.MIN_PRICE]: number;
  [FilterKey.MAX_PRICE]: number;
  [FilterKey.MIN_PRICE_MSRP]: number;
  [FilterKey.MAX_PRICE_MSRP]: number;
  [FilterKey.MIN_POWER]: number;
  [FilterKey.MAX_POWER]: number;
  [FilterKey.HAS_HITCH]: boolean[];
  [FilterKey.IS_FOR_BUSINESS]: boolean[];
  [FilterKey.IS_YOUNG_DRIVER]: boolean[];
  [FilterKey.TOTAL_RESULTS]: number;
};

export type Color = {
  color_hex: string;
  id: string;
  specific?: string;
};

export type Picture = {
  url: string;
};

export type VehicleBrand = {
  helper_brand_logo: Picture;
  id: string;
};

export type Model = {
  id: string;
  picture?: {
    url: string;
  };
  available: boolean;
  cheapest_price?: number;
  cheapest_downpayment_price?: number;
  configs_count?: number;
  grouped_configs_count?: number;
};

export type Brand = VehicleBrand & {
  available: boolean;
  models: Model[];
};

export const enum VehicleViewKey {
  AVAILABLE = 'available_cars',
  AVAILABLE_AND_COMING_SOON = 'available-and-coming-soon',
  COMING_SOON = 'coming_soon',
  DISPLAYED_CARS = 'displayed_cars',
}

export type GetFiltersParams = {
  hide_related?: boolean;
  filters: FilterValuesObject;
  zipCode?: string;
  locale: string;
  pricingType?: PricingType;
};

/**
 * Returns possible values for Product API cars' filters.
 *
 * Flow explained:
 *
 * 1. It fetches two sets of filters – one for the `available` view, and one for
 * the `displayed-cars` view. The reason behind this is the fact that `displayed-cars`
 * contains more data and is in fact a superset of the `available` filters.
 * We need to extract all possible brand values to make the filter URL parsing for
 * the out-of-stock brands and models possible.
 *
 * 2. It merges brands and models of both `displayed-cars` and `available` filters
 * and fixes the `available` property for both brands and models. All the brands and models
 * from the `available` view should remain being available, however there are other brands
 * in the `displayed-cars` view that come with `available: true`, which is incorrect since
 * they are not listed in the `available` view and have no available cars. There are also
 * models inside the available brands of the `displayed-cars` view that are unavailable in the
 * `available` view, but still come with `available: true`, which is incorrect by the same reason.
 * So while merging happens, the difference between `displayed-cars` and `available` views
 * is merged into the response from the `available` view with the property `available: false`.
 *
 * @param params
 * @returns possible filter values required for filter parsing and UI
 */

export const processFilters = (
  availableFilters: FiltersResponse,
  displayedFilters: FiltersResponse
) => {
  const filtersResponse: FiltersResponse = {
    ...availableFilters!,
    brands: [],
    models: [],
  };

  const availableBrandsMap = new Map(
    availableFilters!?.[FilterKey.BRANDS].map((brand) => [brand.id, brand])
  );

  const displayedBrandsMap = new Map(
    displayedFilters!?.[FilterKey.BRANDS].map((brand) => [brand.id, brand])
  );

  const allBrandIds = union(
    displayedFilters!?.[FilterKey.BRANDS].map((brand) => brand.id),
    availableFilters!?.[FilterKey.BRANDS].map((brand) => brand.id)
  );

  for (const brandId of allBrandIds) {
    const availableBrand = availableBrandsMap.get(brandId);
    const displayedBrand = displayedBrandsMap.get(brandId)!;

    if (!availableBrand) {
      filtersResponse.brands.push({
        ...displayedBrand,
        available: false,
        models: displayedBrand.models.map((model) => ({
          ...model,
          available: false,
        })),
      });

      continue;
    }

    const models = [
      ...displayedBrand.models.map((model) => ({ ...model, available: false })),
      ...availableBrand.models,
    ];

    filtersResponse.brands.push({
      ...availableBrand,
      models,
    });
  }

  filtersResponse[FilterKey.MODELS] =
    (displayedFilters &&
      availableFilters && [
        ...displayedFilters![FilterKey.MODELS],
        ...availableFilters![FilterKey.MODELS],
      ]) ||
    [];

  return filtersResponse as FiltersResponse;
};

export const enum SortKey {
  ASC = 'asc',
  DESC = 'desc',
  AVAILABILITY = 'availability',
  DEFAULT = 'default',
  LAST_ADDED = 'last_added',
}

function arrayOf<T>(zodType: z.ZodType<T>) {
  return zodType
    .or(z.array(zodType))
    .transform((value) => (Array.isArray(value) ? value : [value]));
}

function date() {
  return z.string().regex(/\d{4}-\d{2}-\d{2}/);
}

export const FilterValuesObjectSchema = z
  .object({
    [FilterKey.PRICING_TYPE]: z.enum([
      PRICING_TYPE.NORMAL,
      PRICING_TYPE.DOWNPAYMENT,
    ]),
    [FilterKey.HIDE_RELATED]: z.boolean().optional(),
    [FilterKey.COMPANY_ID]: z.string().optional(),
    [FilterKey.BRANDS]: arrayOf<string>(z.string()),
    [FilterKey.CAR_TYPES]: arrayOf<string>(z.string()),
    [FilterKey.GEARSHIFTS]: arrayOf<string>(z.string()),
    [FilterKey.MODELS]: arrayOf<string>(z.string()),
    [FilterKey.FUELS]: arrayOf<string>(z.string()),
    [FilterKey.COLORS]: arrayOf<string>(z.string()),
    [FilterKey.TERMS]: arrayOf<number>(z.coerce.number().positive()),
    [FilterKey.MIN_PRICE]: z.coerce.number().positive(),
    [FilterKey.MAX_PRICE]: z.coerce.number().positive(),
    [FilterKey.MIN_PRICE_MSRP]: z.coerce.number().positive(),
    [FilterKey.MAX_PRICE_MSRP]: z.coerce.number().positive(),
    [FilterKey.MIN_POWER]: z.coerce.number().positive(),
    [FilterKey.MAX_POWER]: z.coerce.number().positive(),
    [FilterKey.SORT_BY_POPULARITY]: z.coerce.boolean(),
    [FilterKey.HAS_HITCH]: z.coerce.boolean(),
    [FilterKey.IS_FOR_BUSINESS]: z.coerce.boolean(),
    [FilterKey.IS_YOUNG_DRIVER]: z.coerce.boolean(),
    [FilterKey.AVAILABLE_FROM]: date(),
    [FilterKey.AVAILABLE_TO]: date(),
    [FilterKey.SORT]: z.enum([
      SortKey.DEFAULT,
      SortKey.LAST_ADDED,
      SortKey.ASC,
      SortKey.DESC,
      SortKey.AVAILABILITY,
    ]),
    [FilterKey.OFFER_TYPE]: z.enum([VehicleOfferType.SUBSCRIPTION]),
    [FilterKey.VIEW]: z.enum([
      VehicleViewKey.AVAILABLE,
      VehicleViewKey.AVAILABLE_AND_COMING_SOON,
      VehicleViewKey.COMING_SOON,
      VehicleViewKey.DISPLAYED_CARS,
    ]),
    [FilterKey.RETENTION]: z.enum([FilterKey.RETENTION]),
    [FilterKey.HAS_DEALS]: z.coerce.boolean(),
    [FilterKey.PRODUCT_GROUP]: arrayOf<string>(z.string()),
    [FilterKey.HAS_RETENTION_DEALS]: z.coerce.boolean(),
    [FilterKey.FEATURES]: arrayOf<string>(z.string()),
    [FilterKey.TOTAL_RESULTS]: z.coerce.number().positive(),
    [FilterKey.POPULAR]: arrayOf<string>(z.string()),
  })
  .partial()
  .nullable();

export type FilterValuesObject = NonNullable<
  z.infer<typeof FilterValuesObjectSchema>
>;

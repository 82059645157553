import { BASE_SUBSCRIPTION_PATH } from '@finn/ua-constants';
import { Features } from '@finn/ua-featureflags';
import { type BasePageData } from '@finn/ui-cosmic';
import {
  generateLocalizationHeaders,
  isServer,
  Locale,
} from '@finn/ui-utils/ssr';
import Cookies from 'js-cookie';

import { type GenericVehicleDetails } from '../../product-cards';
import {
  FilterKey,
  FilterValuesObject,
  PRICING_TYPE,
  processFilters,
  VehicleViewKey,
} from '../helpers/filters';
import { getPageSize } from '../helpers/page-size';
import { deserializeFiltersFromPath } from '../helpers/parser';
import { getIsOutOfStock } from '../helpers/stock';
import { getClosedUserGroupId } from '../helpers/utils';
import { type FiltersType } from '../hooks/useProductListItems';
import { defaultApiConfig } from './api-config';
import { dynamicFetcher } from './fetchers';
import { type DiscoveryConfigContextType } from './MetadataProvider';

export type GetVehiclesParams = {
  locale: string;
  zipCode?: string;
  filters?: FilterValuesObject;
  offset?: number;
  limit?: number;
};

export type GetVehiclesResponse = {
  offset: number;
  total_results: number;
  results: GenericVehicleDetails[];
};

export const createProductFetchers = (
  apiConfig: DiscoveryConfigContextType['apiConfig'] = defaultApiConfig
) => {
  const fetchProductListItems = async ({
    filters = {},
    offset,
    limit,
    locale,
    zipCode,
  }: GetVehiclesParams): Promise<GetVehiclesResponse> => {
    const hideDownpayment =
      !isServer() &&
      Cookies.get(`${locale}_${Features.HideDownPayment}`) === 'b';
    const cugId = getClosedUserGroupId();

    const pricingType = hideDownpayment
      ? PRICING_TYPE.NORMAL
      : PRICING_TYPE.DOWNPAYMENT;

    const vehiclesResponse = await dynamicFetcher<GetVehiclesResponse>({
      url: apiConfig.products,
      headers: generateLocalizationHeaders(locale),
      query: {
        pricing_type: pricingType,
        ...filters,
        offset,
        limit,
        zipcode: zipCode,
        cug_id: cugId,
        hide_related: true,
      },
    });

    return {
      offset: vehiclesResponse.offset ?? 0,
      total_results: vehiclesResponse.total_results,
      results: vehiclesResponse.results,
    };
  };

  const getVehicleConfig = ({
    vehicleId,
    locale,
    query,
  }: {
    vehicleId: string;
    locale: string;
    query?: Record<string, string>;
  }) => {
    const cugId = getClosedUserGroupId();

    return {
      url: `${apiConfig.products}/${encodeURIComponent(vehicleId)}`,
      headers: generateLocalizationHeaders(locale),
      query: {
        cug_id: cugId || null,
        hide_related: true,
        ...query,
      },
    };
  };

  return {
    fetchProductListItems,
    getVehicleConfig,
    fetchVehicle: async ({
      vehicleId,
      locale,
      query,
    }: {
      vehicleId: string;
      locale: string;
      hide_related?: boolean;
      query?: Record<string, string>;
    }) =>
      dynamicFetcher<GenericVehicleDetails>(
        getVehicleConfig({ vehicleId, locale, query })
      ),
    fetchAdjustedProductListItemsResponse: (
      filterValues: FilterValuesObject,
      locale: string,
      pageData?: BasePageData
    ) =>
      fetchProductListItems({
        filters: {
          ...filterValues,
          [FilterKey.VIEW]: VehicleViewKey.DISPLAYED_CARS,
        },
        limit: getPageSize(true, pageData?.metadata?.campaign_cards?.length),
        locale,
      }),
    fetchProductItems: async ({
      locale,
      slugArray,
      availableFilters,
      displayedFilters,
      pageData,
    }: {
      locale?: string;
      slugArray?: string[];
      availableFilters?: FiltersType;
      displayedFilters: FiltersType;
      pageData?: BasePageData;
    }) => {
      const filterLocale = locale === Locale.ENGLISH_USA ? 'en-US' : 'de-DE';
      const filtersResponse = processFilters(
        availableFilters?.[filterLocale],
        displayedFilters?.[filterLocale]
      );

      const filterValues = slugArray
        ? deserializeFiltersFromPath(
            [...slugArray].join('/'),
            BASE_SUBSCRIPTION_PATH,
            locale,
            filtersResponse
          )
        : {};

      const isOutOfStock = getIsOutOfStock(filterValues, filtersResponse);
      //IS OUT OF STOCK RETURNS FALSE WHILE IT SHOULD BE TRUE

      const productListItemsResponse = await fetchProductListItems({
        filters: {
          ...filterValues,
          [FilterKey.VIEW]: isOutOfStock
            ? VehicleViewKey.DISPLAYED_CARS
            : locale === Locale.ENGLISH_USA
              ? VehicleViewKey.AVAILABLE_AND_COMING_SOON
              : VehicleViewKey.AVAILABLE,
        },
        limit: getPageSize(
          isOutOfStock,
          pageData?.metadata?.campaign_cards?.length
        ),
        locale,
      });

      return {
        filterValues,
        productListItemsResponse,
        isOutOfStock,
        filtersResponse,
      };
    },
  };
};

import { CheckoutStep } from '@finn/ua-auth';
import { IS_DIRECT_CHECKOUT_ENABLED } from '@finn/ua-constants';
import { useDownPayment } from '@finn/ua-featureflags';
import { ModalKey, useOpenModal } from '@finn/ua-modals';
import { TrackingEventName } from '@finn/ua-tracking';
import {
  CookieKeys,
  getClientCookie,
  setClientBooleanCookie,
  setClientCookie,
  setClientJSONCookie,
  trackEvent,
  useCurrentLocale,
} from '@finn/ui-utils';
import { useSearchParams } from 'next/navigation';
import { NextRouter } from 'next/router';
import { useEffect } from 'react';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { getClosedUserGroupId } from '../../core';
import { useDiscoveryConfig } from '../../core/data/MetadataProvider';
import { GenericVehicleDetails } from '../../product-cards';
import { calculateTotalPrice, getDefaultTerm } from '../../product-pricing';
import {
  getEcommerceProduct,
  triggerEcommerceOrderingTrackingEvent,
} from '../../tracking';

export const getCheckoutCartUrl = (
  vehicleId: string,
  term: number | string,
  kmPackage: number | string,
  downPaymentAmount?: number
): string => {
  if (downPaymentAmount) {
    return `/checkout/cart/${vehicleId}/${term}/${kmPackage}?downPaymentAmount=${downPaymentAmount}`;
  }

  return `/checkout/cart/${vehicleId}/${term}/${kmPackage}`;
};

// Default distance options for different regions.
export const defaultKmOption = 1000;

export const getShortestKmOption = (
  vehicle: GenericVehicleDetails,
  { forceRegularPrice, term }: { term?: number; forceRegularPrice?: boolean }
) => {
  if (forceRegularPrice && term && (vehicle.price?.[term] as any)?.extra_1000) {
    return 500;
  }

  if (vehicle.price.extra_1000) {
    return 500;
  }

  return defaultKmOption;
};

export type DirectCheckoutEligibility = (
  dealInfo: {
    vehicleId: string;
    zipcode?: string;
  },
  {
    timeout,
    locale,
  }: {
    timeout?: number;
    locale: string;
  }
) => Promise<{
  checkoutStep: string;
  allowDirectCheckout: boolean;
}>;

type ConfigureActions = {
  // Public
  setTerm(term: number): void;
  setKilometerPackage(kmPackage: number): void;
  setDownPayment(downPayment: boolean): void;

  /**
   * Main submit action of the page, will try to do Direct Checkout if available
   * */
  onContinue(directCheckout): Promise<void>;

  /**
   * Called from within the Direct Checkout Confirm popup
   * */
  onDirectCheckoutContinue(onDirectCheckoutContinue: {
    accepted?: boolean;
    directCheckout;
  }): Promise<void>;

  // Dependencies
  setUser(
    contactId: string,
    directCheckoutEligibility: DirectCheckoutEligibility
  ): Promise<void>;
  setVehicle(
    vehicle: GenericVehicleDetails,
    locale: string,
    directCheckoutEligibility: DirectCheckoutEligibility,
    forceRegularPrice: boolean,
    initialTerm?: string
  ): Promise<void>;
  setZipCode(
    zipCode: string,
    directCheckoutEligibility: DirectCheckoutEligibility
  ): Promise<void>;
  setRouter(router: NextRouter, initialTerm: string);
  setOpenModal(openModal: (key: ModalKey) => void);

  // Internal
  goToCart({ isDirect }?: { isDirect?: boolean }): Promise<void>;
  verifyDirectCheckout({
    directCheckoutEligibility,
  }: {
    directCheckoutEligibility: DirectCheckoutEligibility;
  }): Promise<void>;
  setInitialTerm(initialTerm: string): void;
  setOnCheckout(
    cb: ({ id, term, kilometerPackage, downPaymentAmount }) => void
  ): void;
};

type ConfigureState = ConfigureActions & {
  downPaymentEnabled: boolean;
  downPaymentAmount: number;
  isLoading: boolean;
  downPayment: boolean;
  term: number;
  kilometerPackage: number;
  directCheckoutCheckResult?: {
    checkoutStep: string;
    allowDirectCheckout: boolean;
  };
  monthlyPrice: number;

  // Dependencies
  vehicle?: GenericVehicleDetails;
  zipCode?: string;
  contactId?: string;
  locale?: string;
  router?: NextRouter;
  openModal?: (key: ModalKey) => void;
  onCheckout?: ({ id, term, kilometerPackage, downPaymentAmount }) => void;
};

const initialState = {
  term: 0,
  monthlyPrice: 0,
  isLoading: false,
  downPayment: true,
  kilometerPackage: 0,
  downPaymentAmount: 1200,
  downPaymentEnabled: true,
};

const STORE_NAME = 'configure';
enum ActionNames {
  SET_TERM = 'setTerm',
  SET_DOWNPAYMENT = 'setDownPayment',
  SET_KILOMETER_PACKAGE = 'setKilometerPackage',
  VERIFY_DIRECT_CHECKOUT = 'verifyDirectCheckout',
  ON_CONTINUE = 'onContinue',
  GO_TO_CART = 'goToCart',
  SET_INITIAL_TERM = 'setInitialTerm',
  ON_DIRECT_CHECKOUT_CONTINUE = 'onDirectCheckoutContinue',
  SET_ON_CHECKOUT = 'setOnCheckout',
}

// TODO type mess here, not sure how to fix without causing cycles
export type DirectCheckout = (
  dealInfo: {
    vehicleId: string;
    term?: number;
    kilometerPackage?: number;
    zipcode?: string;
    downpaymentSelected?: boolean;
    cugId?: string;
  },
  object
) => Promise<any>;

export const useConfigureStore = create<ConfigureState>()(
  devtools(
    (set, get) => ({
      ...initialState,
      setTerm(term: number) {
        set(
          ({ downPayment }) => ({
            term,
            downPaymentEnabled: term !== 1,
            downPayment: term !== 1 && downPayment,
          }),
          false,
          ActionNames.SET_TERM
        );
      },
      setKilometerPackage(kilometerPackage: number) {
        set(
          () => ({ kilometerPackage }),
          false,
          ActionNames.SET_KILOMETER_PACKAGE
        );
      },

      setDownPayment(downPayment: boolean) {
        set(() => ({ downPayment }), false, ActionNames.SET_DOWNPAYMENT);
      },

      setOnCheckout(onCheckout) {
        set(() => ({ onCheckout }), false, ActionNames.SET_ON_CHECKOUT);
      },

      async verifyDirectCheckout({ directCheckoutEligibility }) {
        const { contactId, zipCode, vehicle, locale } = get();
        if (!contactId || !IS_DIRECT_CHECKOUT_ENABLED || !vehicle) {
          return;
        }

        const directCheckoutCheckResult = await directCheckoutEligibility(
          {
            vehicleId: vehicle.id,
            zipcode: zipCode,
          },
          { locale }
        );

        set(
          () => ({
            directCheckoutCheckResult,
          }),
          false,
          ActionNames.VERIFY_DIRECT_CHECKOUT
        );
      },

      async onContinue(directCheckout: DirectCheckout) {
        set(() => ({ isLoading: true }), false, ActionNames.ON_CONTINUE);

        const {
          contactId,
          vehicle,
          zipCode,
          onDirectCheckoutContinue,
          directCheckoutCheckResult,
          term,
          kilometerPackage,
          goToCart,
        } = get();

        const isTesting =
          getClientCookie(CookieKeys.DISABLE_DIRECT_CHECKOUT_FOR_E2E) === 'yes';

        // reset to show delivery fee modal again for new checkout
        setClientCookie(CookieKeys.DELIVERY_MODAL_SHOWED, 'no');

        const trackingProps = getEcommerceProduct(vehicle, {
          term,
          kmPackage: kilometerPackage,
        });

        if (contactId && !isTesting) {
          if (
            !directCheckoutCheckResult ||
            !directCheckoutCheckResult?.allowDirectCheckout
          ) {
            trackEvent(TrackingEventName.DIRECT_CHECKOUT_INELIGIBLE, {
              directCheckoutCheckResult,
              vehicleId: vehicle?.id,
              zipCode,
              financingType: vehicle?.financing_type,
              contactId,
            });

            goToCart();

            return;
          } else {
            trackEvent(TrackingEventName.DIRECT_CHECKOUT_WITHOUT_MODAL, {
              ...trackingProps,
              directCheckoutStep: directCheckoutCheckResult?.checkoutStep,
            });
            await onDirectCheckoutContinue({ accepted: true, directCheckout });

            return;
          }
        }

        await goToCart();
      },

      async onDirectCheckoutContinue({
        goToNextCheckoutStep,
        directCheckout,
      }: {
        directCheckout: DirectCheckout;
        goToNextCheckoutStep: (
          router: NextRouter,
          nextStage: string,
          dealData: {
            contactId?: number | string;
            dealId?: number | string;
            hash?: string;
            isDirectCheckout?: boolean;
          },
          isCart?: boolean
        ) => Promise<void>;
      }) {
        set(
          () => ({ isLoading: true }),
          false,
          ActionNames.ON_DIRECT_CHECKOUT_CONTINUE
        );

        const {
          vehicle,
          term,
          kilometerPackage,
          goToCart,
          zipCode,
          locale,
          router,
          downPayment,
        } = get();

        try {
          const res = await directCheckout(
            {
              vehicleId: vehicle.id,
              term,
              kilometerPackage,
              zipcode: zipCode,
              downpaymentSelected: downPayment,
              cugId: getClosedUserGroupId(),
            },
            { locale }
          );

          if (!res.deal.id) {
            goToCart();
          } else {
            // todo: set default availability and swap
            setClientBooleanCookie(CookieKeys.DC_TRIGGER_DEAL_EVENT, true);
            setClientJSONCookie(CookieKeys.DC_CREDIT_SCORE, res?.creditScore);
            const checkoutStep = res?.checkoutStep;
            const dealData = {
              contactId: res?.contact?.id,
              dealId: res?.deal?.id,
              hash: res?.hash,
              isDirectCheckout: [
                CheckoutStep.PAYMENT,
                CheckoutStep.CONFIRMATION,
              ].includes(checkoutStep as CheckoutStep),
            };
            goToCart({ isDirect: true });
            goToNextCheckoutStep(router, checkoutStep, dealData);
          }
        } catch {
          goToCart();
        }
      },

      async goToCart({ isDirect } = {}) {
        set(() => ({ isLoading: false }), false, ActionNames.GO_TO_CART);
        const {
          vehicle,
          router,
          kilometerPackage,
          term,
          downPayment,
          onCheckout,
        } = get();
        const monthlyPrice = calculateTotalPrice({
          vehicle,
          kilometerPackage,
          term,
          isDownPayment: downPayment,
        });
        const monthlyPriceWithoutDownpayment = calculateTotalPrice({
          vehicle,
          kilometerPackage,
          term,
          isDownPayment: false,
        });

        const downPaymentAmountInVehicle =
          vehicle?.downpayment_prices?.downpayment_fixed_amount ||
          vehicle?.downpayment_prices?.fixed_amount;

        triggerEcommerceOrderingTrackingEvent({
          eventName: TrackingEventName.PRODUCT_ADDED,
          kmPackage: kilometerPackage,
          monthlyPrice,
          monthlyPriceWithoutDownpayment,
          downPaymentAmount: downPayment
            ? downPaymentAmountInVehicle
            : undefined,
          term,
          vehicle,
        });
        if (isDirect) return;

        const downPaymentAmount = downPayment
          ? downPaymentAmountInVehicle
          : undefined;

        const url = getCheckoutCartUrl(
          vehicle.id,
          term,
          kilometerPackage,
          downPaymentAmount
        );

        if (onCheckout) {
          return onCheckout({
            id: vehicle?.id,
            term,
            kilometerPackage,
            downPaymentAmount,
          });
        }
        router?.push(url);
      },

      setInitialTerm(initialTerm) {
        const { vehicle, router } = get();

        if (!vehicle) return;

        const defaultTerm = getDefaultTerm(vehicle);
        const queryTerm = router?.query?.term || initialTerm;

        const parsedTerm = queryTerm && parseInt(queryTerm as string);
        const isValidTerm = vehicle.available_terms.includes(parsedTerm);

        const term = (isValidTerm && parsedTerm) || defaultTerm;

        set(() => ({ term }), false, ActionNames.SET_INITIAL_TERM);
      },

      // Dependencies
      setOpenModal(openModal) {
        set(() => ({ openModal }));
      },

      async setVehicle(
        vehicle,
        locale,
        directCheckoutEligibility,
        forceRegularPrice,
        initialTerm
      ) {
        const kilometerPackage = getShortestKmOption(vehicle, {
          term: get().term || getDefaultTerm(vehicle),
          forceRegularPrice,
        });

        set(() => ({
          vehicle,
          locale,
          kilometerPackage,
          isLoading: false,
        }));
        await get().verifyDirectCheckout({ directCheckoutEligibility });

        get().setInitialTerm(initialTerm);
      },

      async setUser(contactId: string, directCheckoutEligibility) {
        set(() => ({ contactId }));
        await get().verifyDirectCheckout({ directCheckoutEligibility });
      },

      async setZipCode(zipCode: string, directCheckoutEligibility) {
        set(() => ({ zipCode }));
        await get().verifyDirectCheckout({ directCheckoutEligibility });
      },

      async setRouter(router: NextRouter, initialTerm?: string) {
        set(() => ({ router }));

        get().setInitialTerm(initialTerm);
      },
    }),
    { name: STORE_NAME }
  )
);

/**
 * Initializes the Configure Store while loading its dependencies (location store, vehicle context, modal ...)
 * */
export const useInitializeConfigureStore = (
  vehicle: GenericVehicleDetails,
  directCheckoutEligibility: DirectCheckoutEligibility,
  contactId: string,
  {
    onCheckout,
  }: {
    onCheckout?: ({ id, term, kilometerPackage, downPaymentAmount }) => void;
  } = {}
) => {
  const {
    setUser,
    setRouter,
    setVehicle,
    setOpenModal,
    setDownPayment,
    setOnCheckout,
  } = useConfigureStore();
  const { forceRegularPrice } = useDiscoveryConfig();
  const { isDownPaymentEnabled: isDownPaymentEnabledRaw } = useDownPayment();
  const isDownPaymentEnabled = forceRegularPrice
    ? false
    : isDownPaymentEnabledRaw;

  const { locale } = useCurrentLocale();
  const { router } = useDiscoveryConfig();
  const searchParams = useSearchParams();
  const term = String(searchParams?.get('term'));

  const openModal = useOpenModal();

  useEffect(() => {
    setDownPayment(isDownPaymentEnabled);
  }, [isDownPaymentEnabled, setDownPayment]);

  useEffect(() => {
    setOnCheckout(onCheckout);
  }, [onCheckout, setOnCheckout]);

  useEffect(() => {
    setRouter(router, term);
  }, [router, setRouter, term]);

  useEffect(() => {
    setOpenModal(openModal);
  }, [openModal, setOpenModal]);

  useEffect(() => {
    if (vehicle) {
      setVehicle(
        vehicle,
        locale,
        directCheckoutEligibility,
        forceRegularPrice,
        term
      );
    }
  }, [locale, setVehicle, vehicle, forceRegularPrice, term]);

  useEffect(() => {
    setUser(contactId, directCheckoutEligibility);
  }, [contactId, setUser]);
};

import {
  CountryCode,
  getFormattedNumber,
  IntlShape,
  twoDecimalPlaces,
} from '@finn/ui-utils';
import get from 'lodash/get';

import { getKmPackagePriceWithoutVat } from '../../product-pricing';
import { GenericVehicleDetails } from '../types';

// TODO extract product related things to separate lib
export const convertKwToPs = (powerInKw: number): number =>
  Math.round(powerInKw * 1.35962);

export const convertKwToHP = (powerInKw: number): number =>
  Math.round(powerInKw * 1.34102);

export const getPowerLabel = (
  intl: IntlShape,
  powerInKw: number,
  region: string,
  isElectric: boolean
): string => {
  const isUSA = region === CountryCode.US;

  let label = '';

  if (isUSA) {
    const powerInHP = convertKwToHP(powerInKw);
    label = isElectric
      ? `${getFormattedNumber(intl, powerInKw)} kW (${getFormattedNumber(
          intl,
          powerInHP
        )} hp)`
      : `${powerInHP} hp`;
  } else {
    const powerInPs = convertKwToPs(powerInKw);
    label = `${getFormattedNumber(intl, powerInKw)} kW (${getFormattedNumber(
      intl,
      powerInPs
    )} PS)`;
  }

  return label;
};

export const getVehicleMonthlyPrice = (
  vehicle: GenericVehicleDetails | undefined,
  term: number | undefined,
  kmPackage: number | undefined,
  isForBusiness?: Boolean,
  isDownPayment?: Boolean
): number => {
  const priceKey = isDownPayment ? 'downpayment_prices' : 'price';
  const clientTypeStr = isForBusiness ? 'b2b' : 'b2c';
  const price =
    get(vehicle, `${priceKey}.available_price_list.${clientTypeStr}_${term}`) ||
    get(vehicle, `${priceKey}.${clientTypeStr}_${term}`, 0);
  const kmPackagePrice = get(vehicle, `price.extra_${kmPackage}`, 0);

  const total =
    price + getKmPackagePriceWithoutVat(kmPackagePrice, !!isForBusiness);

  return twoDecimalPlaces(total);
};

import { SelfServiceRoutes } from '@finn/ua-constants';
import {
  clearClientCookie,
  CookieKeys,
  IFinnSession,
  trackEvent,
} from '@finn/ui-utils';
import { useRouter } from 'next/compat/router';
import { usePathname } from 'next/navigation';
import { MouseEvent } from 'react';

import { signOut } from './auth';
import { checkAccountSwitch } from './checkAccountSwitch';

const AUTHENTICATED_ROUTES = [
  SelfServiceRoutes.MYACCOUNT,
  SelfServiceRoutes.CONTACT_DETAILS,
  SelfServiceRoutes.CHANGE_PASSWORD,
  SelfServiceRoutes.DRIVER_MGMT,
  SelfServiceRoutes.ADD_DRIVER,
  SelfServiceRoutes.COMPANY_DETAILS,
  SelfServiceRoutes.MY_CARS,
];

export const useSignOut = ({ session }: { session: IFinnSession | null }) => {
  const router = useRouter();
  const pathname = usePathname();

  const handleSignOut = async () => {
    await checkAccountSwitch({ session }); // this will set the cookie for accounts already logged in enabling us to track the account switch
    trackEvent('Account Logged Out', {});
    clearClientCookie(CookieKeys.LAST_DEAL_INFO);
    clearClientCookie(CookieKeys.TEMP_DELIVERY_DATE);
    clearClientCookie(CookieKeys.TEMP_SWAP_DEAL_ID);
    clearClientCookie(CookieKeys.RETURN_TO_CKO);
    if (AUTHENTICATED_ROUTES.some((route) => pathname.includes(route))) {
      // this is a mid-term solution for the sign-out problem
      // the real cause here is the basePath of the auth provider of B2C app
      // so instead we are doing the API calls manually, refer to Mahmoud Abuhassan for more info if needed

      try {
        const origin = window?.origin;
        await signOut(`${origin}/`);
      } catch (err) {
        // to handle error
      }
      // To prevent login prompt if user is logging out from a page which needs authentication
      router?.push('/home');
    } else {
      await signOut();
      // For user interaction purpose
      window.location.reload();
    }
  };

  const handleSignOutClick = async (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    await handleSignOut();
  };

  return { handleSignOutClick, handleSignOut };
};

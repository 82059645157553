import { Brand, useFetchFilters } from '@finn/ua-vehicle';
import { Locale, useCurrentLocale } from '@finn/ui-utils';
import { createContext, ReactNode } from 'react';

export const NavigationMenuContext = createContext<Values>({
  filterBrandsModels: {
    brands: [],
    models: [],
  },
});

type FilterBrandsModels = {
  brands: Brand[];
  models: string[];
};

type BrandsAndModelsPerLocale = {
  [Locale.ENGLISH_USA]: FilterBrandsModels;
  [Locale.GERMAN_GERMANY]: FilterBrandsModels;
};

type Values = {
  filterBrandsModels: FilterBrandsModels;
};

export const NavigationMenuProvider = ({
  brandModels,
  children,
}: {
  brandModels: BrandsAndModelsPerLocale;
  children: ReactNode;
}) => {
  const { locale } = useCurrentLocale();

  const { data: clientSideData } = useFetchFilters({
    filters: {},
    locale,
  });

  let preloaded = brandModels[locale];
  if (locale === Locale.ENGLISH_GERMANY) {
    preloaded = brandModels[Locale.GERMAN_GERMANY];
  }

  const filterBrandsModels = clientSideData
    ? ({
        models: clientSideData.models,
        brands: clientSideData.brands,
      } as FilterBrandsModels)
    : preloaded;

  return (
    <NavigationMenuContext.Provider
      value={{
        filterBrandsModels,
      }}
    >
      {children}
    </NavigationMenuContext.Provider>
  );
};

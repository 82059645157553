import {
  config,
  createFetcher,
  FetcherRequestPayload,
  Locale,
} from '@finn/ui-utils/ssr';

const oldFetcher = createFetcher({
  baseURL: config?.FLEET_API_URL,
  withCredentials: false,
});

const fetcher = createFetcher({
  baseURL: config?.PRODUCT_API_URL,
  withCredentials: false,
});

/**
 * SWR doesnt allow us to switch fetcher directly, as it doesnt detect the change.
 * This work-around allows us to query once the correct API without re-rendering.
 */
export const dynamicFetcher = <Response>(request: FetcherRequestPayload) => {
  const locale = request?.headers?.['X-Language-Tag'];

  if (locale === Locale.ENGLISH_USA) {
    return oldFetcher<Response>(request);
  } else {
    return fetcher<Response>(request);
  }
};

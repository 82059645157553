import { Button } from '@finn/design-system/atoms/button';
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalSubtitle,
  ModalTitle,
} from '@finn/design-system/atoms/modal';
import { showToast } from '@finn/design-system/atoms/toast';
import { Check } from '@finn/design-system/icons/check';
import { MailOutlined } from '@finn/design-system/icons/mail-outlined';
import { interactionTrackingEvent, TrackingEventName } from '@finn/ua-tracking';
import { Locale, useCurrentLocale, useIntl, useSession } from '@finn/ui-utils';
import { Dispatch, SetStateAction, useCallback, useState } from 'react';

import { resendVerification } from '../auth';

type EmailVerificationProps = {
  open: boolean;
  onClose(): void;
  modalType: string;
  emailResent?: boolean;
  setEmailResent?: Dispatch<SetStateAction<boolean>>;
};

export const EmailVerificationModal = ({
  open,
  onClose,
  modalType,
  emailResent,
  setEmailResent,
}: EmailVerificationProps) => {
  const i18n = useIntl();
  const { locale } = useCurrentLocale();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [session] = useSession();
  const emailAddress = session?.user?.email;

  const title = i18n.formatMessage(
    `userAccount.messageModal.${modalType}.title`
  );
  const ctaText = i18n.formatMessage(
    `userAccount.messageModal.${modalType}.cta_text`
  );

  const description = i18n.formatMessage(
    `userAccount.messageModal.${modalType}.description`
  );

  const successMessage = i18n.formatMessage(
    `userAccount.messageModal.${modalType}.success_message`
  );

  const onRetryClick = useCallback(async () => {
    if (!emailAddress) {
      showToast({
        status: 'error',
        description: i18n.formatMessage(
          'userAccount.serverErrors.generalError'
        ),
      });

      return;
    }
    interactionTrackingEvent(TrackingEventName.CTA_CLICKED, {
      location: 'email-verification-modal',
    });
    setIsSubmitting(true);
    const { ok } = await resendVerification(emailAddress, locale as Locale);
    setIsSubmitting(false);
    if (ok) {
      setEmailResent?.(true);
    } else {
      showToast({
        status: 'error',
        description: i18n.formatMessage(
          'userAccount.serverErrors.generalError'
        ),
      });
    }
  }, [locale, setEmailResent, emailAddress, i18n]);

  return (
    <Modal
      variant="small"
      open={open}
      onOpenChange={(nextOpen) => {
        if (!nextOpen) {
          onClose();
        }
      }}
    >
      <ModalContent>
        <MailOutlined className="mx-auto min-h-14 min-w-14" />
        <ModalHeader>
          <ModalTitle className="text-center">{title}</ModalTitle>
          <ModalSubtitle className="text-center">{description}</ModalSubtitle>
        </ModalHeader>
        {emailResent ? (
          <p className="body-16-light mt-14 flex flex-col items-center sm:mt-8">
            <Check className="fill-green mb-2" />
            {successMessage}
          </p>
        ) : (
          <ModalFooter>
            <Button onClick={onRetryClick} loading={isSubmitting}>
              {ctaText}
            </Button>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};

import { ContractTermType } from '../types/finance';
import { IntlShape } from './localization/useIntl';

export const getContractTermType = (term: number) =>
  term === 1 ? ContractTermType.OPEN : ContractTermType.FIXED;

export const getLabeledTerm = (term: number, intl: IntlShape) => {
  const label = term === 1 ? 'general.termLabelOne' : 'general.termLabelOther';

  return intl.formatMessage(label, {
    duration: term,
  });
};

export const getTermLabel = (
  term: number,
  flexTermLabel: string,
  intl: IntlShape
): string => {
  const isFixedTerm = getContractTermType(term) === ContractTermType.FIXED;

  const termLabel = isFixedTerm
    ? getLabeledTerm(term, intl)
    : String(flexTermLabel || term);

  return String(termLabel);
};

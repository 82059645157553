export const IN_STOCK_LIMIT = 24;
export const OUT_OF_STOCK_LIMIT = 8;

export const getPageSize = (isOutOfStock: boolean, fixedCards: number = 0) => {
  const reqLimit = isOutOfStock ? OUT_OF_STOCK_LIMIT : IN_STOCK_LIMIT;
  // We show out of stock pages with a paginated grid.
  // The limit should be multiple of 4 to perfectly fit the grid layout
  if (isOutOfStock) {
    return reqLimit - fixedCards;
  }

  return reqLimit;
};

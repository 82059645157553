import { useMemo } from 'react';

import { getForBusinessCookie } from '../../../helpers';
import { useDiscoveryConfig } from '../../core/data/MetadataProvider';
import { GenericVehicleDetails } from '../../product-cards';
import { getDefaultTerm } from '../helpers/getDefaultTerm';
import { getKilometerPackagePrice } from '../helpers/prices';

type Params = {
  term?: number;
  isDownPayment?: boolean;
  kilometerPackage?: number;
  downPaymentAmount?: number;
  vehicle: GenericVehicleDetails;
  priceType?: string;
  forceRegularPrice?: boolean;
  isPayAsYouGo?: boolean;
};

type PriceListParams = {
  isDownPayment: boolean;
  downPaymentAmount: number;
  vehicle: GenericVehicleDetails;
};

type BasePriceParams = {
  term: number;
  isForBusiness: boolean;
  priceList: number | { [key: string]: number };
};

const getPriceList = ({ vehicle, isDownPayment }: PriceListParams) => {
  if (isDownPayment) {
    return vehicle?.downpayment_prices?.available_price_list;
  }

  return vehicle?.price?.available_price_list;
};

const getBasePrice = ({
  term,
  priceList,
  isForBusiness,
}: BasePriceParams): number => {
  const priceKey = `${isForBusiness ? 'b2b' : 'b2c'}_${term}`;

  return typeof priceList === 'object' ? (priceList?.[priceKey] ?? 0) : 0;
};

export const calculateTotalPrice = (params: Params): number => {
  const {
    term,
    vehicle,
    kilometerPackage = 0,
    downPaymentAmount = 0,
    forceRegularPrice,
    isDownPayment = false,
    isPayAsYouGo = false,
  } = params;

  if (!vehicle) {
    return 0;
  }

  const isForBusiness = getForBusinessCookie();
  const adjustedTerm = term || getDefaultTerm(vehicle);

  const priceList = getPriceList({
    vehicle,
    isDownPayment,
    downPaymentAmount,
  });

  const basePrice = getBasePrice({
    priceList,
    isForBusiness,
    term: adjustedTerm,
  });

  const kmPackagePrice = getKilometerPackagePrice({
    vehicle,
    isForBusiness,
    kilometerPackage,
    forceRegularPrice,
    term: adjustedTerm,
    isPayAsYouGo,
  });

  const res = basePrice + kmPackagePrice;

  return params?.priceType === 'cents' ? res / 100 : res;
};

export const useCalculatePrice = (params: Params) => {
  const { priceType, forceRegularPrice } = useDiscoveryConfig();

  return useMemo(
    () => calculateTotalPrice({ ...params, priceType, forceRegularPrice }),
    [params, priceType, forceRegularPrice]
  );
};

import { Deal } from '@finn/platform-modules';
import { NextRouter } from 'next/router';
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
} from 'react';

import { useConfigureStore } from '../../pdp-management';
import { defaultApiConfig } from './api-config';
import { createProductFetchers } from './product-fetchers';

/**
 * As different apps use slitly different api endpoints, we need to provide a way to configure the api endpoints.
 */

export type DiscoveryConfigContextType = {
  deals: Deal[];
  forceRegularPrice?: boolean;
  priceType?: 'cents';
  apiConfig: {
    products: string;
    filters: string;
  };
  carPolicy?: {
    companyName?: string;
    brands?: string[];
    models?: string[];
    gearshifts?: string[];
    fuelTypes?: string[];
    carTypes?: string[];
    maxNetPrice?: number;
  };
  subscriptionAttributes?: { key: string }[];
  router: NextRouter | null;
  apiFetchers: ReturnType<typeof createProductFetchers>;
  filterOverrides: {
    [key: string]: {
      hidden?: boolean;
      title?: string;
      hideExtras?: boolean;
    };
  };
};

const defaultConfig: DiscoveryConfigContextType = {
  apiFetchers: createProductFetchers?.(defaultApiConfig),
  deals: [],
  router: null,
  apiConfig: defaultApiConfig,
  filterOverrides: {
    options: {
      hidden: false,
    },
  },
};

const DiscoveryConfigContext =
  createContext<DiscoveryConfigContextType>(defaultConfig);

type DiscoveryConfigProviderProps = DiscoveryConfigContextType & {
  children: ReactNode;
};

export const DiscoveryConfigProvider = ({
  apiConfig = defaultConfig.apiConfig,
  deals = defaultConfig.deals,
  filterOverrides = defaultConfig.filterOverrides,
  subscriptionAttributes,
  carPolicy,
  forceRegularPrice = defaultConfig.forceRegularPrice,
  priceType = defaultConfig.priceType,
  children,
  router,
}: Partial<DiscoveryConfigProviderProps>) => {
  const metadata = useMemo(
    () => ({
      apiFetchers: createProductFetchers(apiConfig),
      apiConfig,
      deals,
      filterOverrides,
      subscriptionAttributes,
      carPolicy,
      forceRegularPrice,
      priceType,
      router,
    }),
    [
      apiConfig,
      deals,
      filterOverrides,
      forceRegularPrice,
      priceType,
      subscriptionAttributes,
      carPolicy,
      router,
    ]
  );

  useEffect(() => {
    if (forceRegularPrice) {
      useConfigureStore.getState().setDownPayment(false);
    }
  }, [forceRegularPrice]);

  return (
    <DiscoveryConfigContext.Provider value={metadata}>
      {children}
    </DiscoveryConfigContext.Provider>
  );
};

export const useDiscoveryConfig = () => useContext(DiscoveryConfigContext);

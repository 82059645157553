import { GenericVehicleDetails } from '../../product-cards';

export const getDefaultTerm = (
  vehicle: Pick<
    GenericVehicleDetails,
    'default_downpayment_term' | 'available_terms'
  >,
  // hopefully temporary hack for b2b, as they do not do this downpyament prcing change
  // and PDP not yet integrated TODO
  { forceRegularPrice = false }: { forceRegularPrice?: boolean } = {
    forceRegularPrice: false,
  }
) => {
  if (forceRegularPrice) {
    return Math.max(...(vehicle.available_terms ?? []));
  }

  return (
    vehicle.default_downpayment_term ??
    Math.max(...(vehicle.available_terms ?? []))
  );
};

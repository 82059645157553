import {
  FilterKey,
  FiltersResponse,
  FilterValuesObject,
  Model,
} from './filters';

export const getIsOutOfStock = (
  filterValues: FilterValuesObject,
  filtersResponse: FiltersResponse
) => {
  const selectedBrandIds = filterValues[FilterKey.BRANDS] ?? null;

  if (selectedBrandIds === null) {
    return false;
  }

  const allBrands = filtersResponse[FilterKey.BRANDS];
  const responseBrands = allBrands.filter((brand) =>
    selectedBrandIds.includes(brand.id)
  );

  if (!responseBrands) {
    return false;
  } else {
    if (responseBrands.every((responseBrand) => !responseBrand?.available)) {
      return true;
    }
  }

  const selectedModelIds = filterValues[FilterKey.MODELS] ?? null;

  if (selectedModelIds === null) {
    return false;
  }

  const responseModels: (Model | undefined)[] = [];
  responseBrands.forEach((responseBrand) => {
    responseBrand?.models.forEach((model) => {
      if (selectedModelIds?.includes(model.id) && model.available) {
        responseModels.push(model);
      }
    });
  });

  return !responseModels.length;
};

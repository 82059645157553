import { VEHICLE_VAT } from '@finn/ua-constants';

import { PRICING_TYPE } from '../../core';
import { type GenericVehicleDetails } from '../../product-cards';

type DistancePrice = {
  price: number;
  distance: number;
  duration: number;
};

type KmPackagePriceParams = {
  isForBusiness: boolean;
  kilometerPackage: number;
  vehicle: GenericVehicleDetails;
  term?: number;
  forceRegularPrice?: boolean;
  isPayAsYouGo?: boolean;
};

export type PricingType = (typeof PRICING_TYPE)[keyof typeof PRICING_TYPE];

// Available distance options in miles and kilometers.
export const kmOptions = [500, 1000, 1500, 2000, 2500, 3000, 4000, 5000];

export function filterPricingList({
  pricingTiers,
}: {
  pricingTiers: Record<string, number | null>;
}) {
  const mileages = ['extra_500', 'extra_1000', 'extra_850'];

  const list = Object.fromEntries(
    Object.entries(pricingTiers).filter(
      ([key, value]) => mileages.includes(key) || value !== null
    )
  );

  return list;
}

export const getKmPackagePriceWithoutVat = (
  price: number,
  isForBusiness: Boolean
) => (isForBusiness ? Math.round(Number(price) / VEHICLE_VAT) : Number(price));

export const mapToDistancePrice = (
  key: string,
  value: number,
  isBusinessClient: boolean,
  priceType: string
): DistancePrice => ({
  duration: 0,
  distance: parseInt(key.substring(6), 10),
  price:
    priceType === 'cents'
      ? getKmPackagePriceWithoutVat(value, isBusinessClient) / 100
      : getKmPackagePriceWithoutVat(value, isBusinessClient),
});

export const shouldIncludePriceOption = (
  key: string,
  distanceOptions: number[]
) => {
  // regex to check for strings of the form extra_500, extra_1000, etc.
  if (!/^extra_\d+$/g.test(key)) {
    return false;
  }

  const distanceValue = parseInt(key.split('_')[1], 10);

  return distanceOptions.includes(distanceValue);
};

export const getDistancePrices = (
  pricingTiers: Record<string, number | null>,
  isBusinessClient: boolean,
  priceType: string,
  term: number,
  forceRegularPrice: boolean
): DistancePrice[] => {
  // We only want to show the 500 km option if the 1000 km option is available

  // Remove null values that are not the base mileage options
  const list = filterPricingList({ pricingTiers }) as any; // TODO fix types between b2b and ua
  const validPricingTiers = forceRegularPrice ? list[term] : list;

  // If 1000 is not set, thats the base-milage, and we hide the extra_500 option.
  if (!validPricingTiers?.extra_1000 && validPricingTiers?.extra_500) {
    delete validPricingTiers.extra_500;
  }

  /*
    Filter the pricing tiers to only include the distance options we want to show
    Map the pricing tiers to the DistancePrice type
    Sort the distance options by price in ascending order
  */
  const priceData = Object.entries(validPricingTiers)
    .filter(([key]) => shouldIncludePriceOption(key, kmOptions))
    .map(([key, value]) =>
      mapToDistancePrice(key, value as number, isBusinessClient, priceType)
    )
    .sort((first, second) => first.price - second.price);

  return priceData;
};

export const PAY_AS_YOU_GO_PRICE_FACTOR = 0.0000025;

export const calculateBasePriceDiscount = (vehicle: GenericVehicleDetails) => {
  const { msrp } = vehicle.price;

  if (msrp < 35000) {
    return 9;
  }

  if (msrp < 75000) {
    return 19;
  }

  if (msrp < 120000) {
    return 29;
  }

  return 39;
};

export const getKilometerPackagePrice = ({
  vehicle,
  isForBusiness,
  kilometerPackage,
  term,
  forceRegularPrice,
  isPayAsYouGo,
}: KmPackagePriceParams): number => {
  const prices = forceRegularPrice ? vehicle?.price?.[term] : vehicle?.price;
  const packagePrice = isPayAsYouGo
    ? -calculateBasePriceDiscount(vehicle)
    : (prices?.[`extra_${kilometerPackage}`] ?? 0);

  return getKmPackagePriceWithoutVat(packagePrice, isForBusiness);
};

import {
  ECCOMMERCE_EVENTS_PROPERTIES,
  interactionTrackingEvent,
  TRACKING_DESTINATIONS,
  trackingErrorHandler,
  TrackingEventName,
} from '@finn/ua-tracking';
import { config, CountryCode, CURRENCIES, trackEvent } from '@finn/ui-utils';
import debounce from 'lodash/debounce';

import {
  GenericVehicleDetails,
  getVehicleMonthlyPrice,
} from '../product-cards';
import { getDefaultTerm, kmOptions } from '../product-pricing';

export const handlePageTracking = (props: {
  type: string;
  pageNumber?: number;
}) => {
  interactionTrackingEvent(TrackingEventName.PAGE_CONTROL_CLICKED, { props });
};

export const ecommerceTrackingEvent = <T>(
  eventName: TrackingEventName,
  properties: T,
  integrations = {},
  region: string = CountryCode.DE
) => {
  const isUSA = region === CountryCode.US;
  const eventProperties = ECCOMMERCE_EVENTS_PROPERTIES[eventName] ?? {};
  trackingErrorHandler(() => {
    trackEvent(
      eventName,
      {
        ...properties,
        currency: isUSA ? CURRENCIES.USD : CURRENCIES.EURO,
        ...eventProperties,
      },
      integrations
    );
  });
};

export type GACustomDimensions = {
  kmPackage?: number;
  productId?: string;
  monthlyPrice?: number;
  dealId?: string | number;
  customerType?: string;
  term?: number;
  coupon_name?: string;
  coupon_id?: string;
  discount?: string | number;
  discount_type?: string;
  email?: string | null;
  directCheckout?: string | boolean;
  skippedPaymentMethod?: string | boolean;
  downPaymentAmount?: number;
  source_flow?: string;
  creditCheckStatus?: string;
  hasGclid?: string | boolean;
  paymentElementUsed?: boolean;
  available?: boolean;
  insurance?: string;
};

export type SelectedProductInfo = GACustomDimensions & {
  term?: number;
  kmPackage?: number;
  position?: number;
  preDiscountPrice?: string;
};

// Param type of triggerEcommerceOrderingTrackingEvent
export type EcommerceOrderingEvent = GACustomDimensions & {
  eventName: TrackingEventName;
  vehicle: GenericVehicleDetails;
  productInfo?: SelectedProductInfo;
  coupon?: string;
  downPaymentAmount?: number;
  monthlyPriceWithoutDownpayment?: number;
};

// https://segment.com/docs/connections/spec/ecommerce/v2/#product-clicked
export type EcommerceProduct = GACustomDimensions & {
  product_id: string;
  sku: string;
  category: string;
  name: string;
  brand: string;
  make?: string;
  model?: string;
  variant: string;
  price: number;
  quantity: number;
  coupon?: string;
  position: number;
  related_configs: number;
  url?: string;
  image_url?: string;
  preDiscountPrice?: string;
};

export const getEcommerceProduct = (
  vehicle: GenericVehicleDetails,
  productInfo: {
    preDiscountPrice?: string;
    term?: number;
    kmPackage?: number;
    position?: number;
  } = {}
): EcommerceProduct => {
  const { position, preDiscountPrice } = productInfo;
  const term = productInfo.term ?? getDefaultTerm(vehicle);
  const kmPackage = productInfo.kmPackage;

  // Get Selected monthly price
  const price = getVehicleMonthlyPrice(vehicle, term, kmPackage);

  // Handle optional fields
  const positionValue = position ? { position } : { position: 1 };

  return {
    product_id: vehicle.id,
    sku: vehicle.vehicle_identification_number,
    category: vehicle.cartype,
    name: `${vehicle.brand.id} ${vehicle.model}`,
    brand: vehicle.brand.id,
    make: vehicle.brand.id,
    model: vehicle.model,
    variant: vehicle.color.specific,
    price,
    quantity: 1,
    related_configs: vehicle.related_configs?.length || 0,
    url: `${config.FINN_WEB_URL}/pdp/${vehicle.id}`,
    image_url: vehicle.picture.url,
    preDiscountPrice,
    ...positionValue,
  };
};

const ecommerceProductListView = () => {
  const impressions = {
    products: [] as Array<EcommerceProduct>,
    set: new Set(),
    listId: '',
    partnerDiscount: 0,
  };

  const triggerProductListViewEvent = debounce(() => {
    ecommerceTrackingEvent(
      TrackingEventName.PRODUCT_LIST_VIEWED,
      {
        eventName: TrackingEventName.PRODUCT_LIST_VIEWED,
        products: impressions.products,
        listId: impressions.listId,
        category: impressions.listId,
        partnerDiscount: impressions.partnerDiscount,
      },
      { [TRACKING_DESTINATIONS.FB]: false } // Because Segment map PRODUCT_LIST_VIEWED to ViewContent which is wrong in our case because we only consider PRODUCT_VIEW as ViewContent, also we can handle it from Segment destination dashboard because we are using the device mode.
    );
  }, 1000);

  return ({
    vehicle,
    list,
    partnerDiscount = 0,
  }: {
    vehicle: GenericVehicleDetails;
    list: string;
    region?: string;
    partnerDiscount?: number;
  }) => {
    if (impressions.set.has(vehicle.id)) return;
    impressions.products.push(
      getEcommerceProduct(vehicle, {
        term: getDefaultTerm(vehicle),
        kmPackage: kmOptions[0],
        position: impressions.products.length + 1,
      })
    );
    impressions.set.add(vehicle.id);
    impressions.listId = list;
    impressions.partnerDiscount = partnerDiscount;
    triggerProductListViewEvent();
  };
};

export const addNewProductListView = ecommerceProductListView();

export const triggerEcommerceOrderingTrackingEvent = ({
  eventName,
  vehicle,
  productInfo,
  coupon,
  ...rest
}: EcommerceOrderingEvent) => {
  ecommerceTrackingEvent(eventName, {
    ...getEcommerceProduct(vehicle, productInfo),
    ...(coupon && { coupon }),
    productId: vehicle.id,
    offerType: vehicle.offer_type,
    coming_soon: vehicle.coming_soon,
    label: vehicle.product_label?.label ?? null,
    fuelType: vehicle.fuel,
    ...rest,
  });
};

export function trackFiltersCleared() {
  interactionTrackingEvent(TrackingEventName.FILTERS_CLEARED, {});
}

type TrackLocationSelectorOpenedParams = {
  type:
    | 'price'
    | 'availability'
    | 'more-filters'
    | 'sorting'
    | 'brand'
    | 'model';
};

export function trackFilterClicked({
  type,
}: TrackLocationSelectorOpenedParams): void {
  interactionTrackingEvent(TrackingEventName.FILTER_CLICKED, {
    type,
  });
}

export function trackFilterTagRemoved() {
  interactionTrackingEvent(TrackingEventName.FILTER_TAG_REMOVED, {});
}

export function trackFilterTagsScrolled() {
  interactionTrackingEvent(TrackingEventName.FILTER_TAGS_SCROLLED, {});
}

export function trackFilterDrawerOpened() {
  interactionTrackingEvent(TrackingEventName.FILTER_DRAWER_OPENED, {});
}

export function trackFilterDrawerClosed() {
  interactionTrackingEvent(TrackingEventName.FILTER_DRAWER_CLOSED, {});
}

export enum FinancingType {
  ABS_CREDIT_SUISSE_1 = 'abs_creditsuisse_1',
  FINN = 'finn',
}

export enum FinancingTypeUS {
  FINN = 'finn_us',
  ABS_FASTONE = 'abs_fastone',
  LEASING_INSPIRATIONMOBILITY = 'leasing_inspirationmobility',
  FINN_US_VCI = 'finn_us_vci',
}

export enum LessorUS {
  FINN_OF_AMERICA = 'finn of America Inc.',
  FINN_TITLING_TRUST = 'FINN Titling Trust',
}

export enum EntityTypeUS {
  TRUST = 'a Trust',
  DELAWARE_CORPORATION = 'a Delaware Corporation',
}

export enum VehicleOfferType {
  SALES = 'sales',
  SUBSCRIPTION = 'subscription',
}

export enum ContractTermType {
  OPEN = 'open-ended',
  FIXED = 'fixed',
}
